export default{
    
    username:'',
    title:'闲牛体验店后台管理系统',
    //localname:'192.168.0.5//aliapp',
    //servename:'xuanzj.wbaohe.com//static/includes',
    
    //品牌
    save_brand_url:'https://tiyan.xianniuzu.com/admin/data.php?op=savebrand',
    get_brand_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getbrand",
    enable_brand_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enablebrand",
    disable_brand_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disablebrand",
    del_brand_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delbrand",
    
    //品类
    save_category_url:'https://tiyan.xianniuzu.com/admin/data.php?op=savecategory',
    get_category_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getcategory",
    enable_category_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enablecategory",
    disable_category_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disablecategory",
    del_category_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delcategory",
    get_tree_url:"https://tiyan.xianniuzu.com/admin/data.php?op=gettree",

    //型号
    save_model_url:'https://tiyan.xianniuzu.com/admin/data.php?op=savemodel',
    get_model_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getmodel",
    enable_model_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enablemodel",
    disable_model_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disablemodel",
    del_model_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delmodel",
    get_select_model_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getselmodel",
    
    //参数
    save_param_url:'https://tiyan.xianniuzu.com/admin/data.php?op=saveparam',
    get_param_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getparam",
    enable_param_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enableparam",
    disable_param_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disableparam",
    del_param_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delparam",
    get_param_val_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getparamval",
    save_param_val_url:"https://tiyan.xianniuzu.com/admin/data.php?op=saveparamval",
    del_param_val_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delparamval",

    //产品
    upload_pdt_detail_url:"https://tiyan.xianniuzu.com/admin/data.php?op=uploadtempimg",
    save_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=savepdt",
    get_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getpdt",
    enable_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enablepdt",
    disable_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disablepdt",
    get_sel_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=editpdt",
    del_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delpdt",
    get_pdt_detail_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getpdtdetail",
    get_pdt_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getpdtplan",
    getpdttemp:"https://tiyan.xianniuzu.com/admin/data.php?op=getpdttemp",
    //模板
    save_temp_url:'https://tiyan.xianniuzu.com/admin/data.php?op=savetemp',
    get_temp_url:"https://tiyan.xianniuzu.com/admin/data.php?op=gettemp",
    enable_temp_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enabletemp",
    disable_temp_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disabletemp",
    del_temp_url:"https://tiyan.xianniuzu.com/admin/data.php?op=deltemp",
    upload_temp_img_url:"https://tiyan.xianniuzu.com/admin/data.php?op=uploadtempimg",
    get_temp_detail:"https://tiyan.xianniuzu.com/admin/data.php?op=getdetail",
    //级联
    get_cascader_url:"https://tiyan.xianniuzu.com/admin/data.php?op=cascader",
    //套餐
    get_plan_model_url:"https://tiyan.xianniuzu.com/admin/data.php?op=planmodel",
    get_plan_pdt_url:"https://tiyan.xianniuzu.com/admin/data.php?op=planpdt",
    get_plan_param_url:"https://tiyan.xianniuzu.com/admin/data.php?op=planparam",
    get_sel_param_url:"https://tiyan.xianniuzu.com/admin/data.php?op=selparam",
    save_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=saveplan",
    get_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getplan",
    del_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delplan",
    enable_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enableplan",
    disable_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disableplan",
    edit_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=editplan",
    get_plan_guarant_url:"https://tiyan.xianniuzu.com/admin/data.php?op=planguarant",
    save_edit_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=saveedit",
    batchsave_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=batchsaveplan",
    batch_add_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=batchaddplan",
    check_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=checkplan",
    temp_save_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=tempsaveplan",
    get_example_plan_url:"https://tiyan.xianniuzu.com/admin/data.php?op=exampleplan",
    get_plan_list:"https://tiyan.xianniuzu.com/admin/data.php?op=getplanlist",

    //保障服务
    save_guarant_url:'https://tiyan.xianniuzu.com/admin/data.php?op=saveguarant',
    get_guarant_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getguarant",
    enable_guarant_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enableguarant",
    disable_guarant_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disableguarant",
    del_guarant_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delguarant",

    //公告
    save_notice_url:'https://tiyan.xianniuzu.com/admin/data.php?op=savenotice',
    get_notice_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getnotice",
    enable_notice_url:"https://tiyan.xianniuzu.com/admin/data.php?op=enablenotice",
    disable_notice_url:"https://tiyan.xianniuzu.com/admin/data.php?op=disablenotice",
    del_notice_url:"https://tiyan.xianniuzu.com/admin/data.php?op=delnotice",
    get_notice_detail:"https://tiyan.xianniuzu.com/admin/data.php?op=getnoticedetail",

    //公用
    updatesession:"https://tiyan.xianniuzu.com/admin/data.php?op=updatesession",
    get_rank_url:'https://tiyan.xianniuzu.com/admin/data.php?op=getrank',
    get_cate_tree_url:'https://tiyan.xianniuzu.com/admin/data.php?op=getcatetree',
    batch_edit_plan:"https://tiyan.xianniuzu.com/admin/data.php?op=batchplan",
    get_region:"https://tiyan.xianniuzu.com/admin/data.php?op=getregion",
    get_logistic_company:"https://tiyan.xianniuzu.com/admin/data.php?op=getlogcom",
    get_return_addr:"https://tiyan.xianniuzu.com/admin/data.php?op=getreturnaddr",
    fileupload:"https://tiyan.xianniuzu.com/admin/data.php?op=fileupload",
    uploadid:"https://tiyan.xianniuzu.com/admin/data.php?op=uploadid",
    savelicense:"https://tiyan.xianniuzu.com/admin/data.php?op=savelicense",
    statisticinfo:"https://tiyan.xianniuzu.com/admin/data.php?op=homepage",

    //库存
    get_stock_url:"https://tiyan.xianniuzu.com/admin/data.php?op=getstock",
    save_stock_url:"https://tiyan.xianniuzu.com/admin/data.php?op=savestock",

    //账单
    get_bill_url: "https://tiyan.xianniuzu.com/admin/data.php?op=getbill",
    save_offline_bill: "https://tiyan.xianniuzu.com/admin/data.php?op=saveoffline",
    withdraw_bill_url: "https://tiyan.xianniuzu.com/admin/data.php?op=withdrawbill",
    refundlist:'https://tiyan.xianniuzu.com/admin/data.php?op=refundlist',
    updaterefund:"https://tiyan.xianniuzu.com/admin/data.php?op=updaterefund",
    getoplog:"https://tiyan.xianniuzu.com/admin/data.php?op=getoplog",
    //订单
    get_order_list:"https://tiyan.xianniuzu.com/admin/data.php?op=getorderlist",
    get_order:"https://tiyan.xianniuzu.com/admin/data.php?op=getorderprice",
    get_apply_list:"https://tiyan.xianniuzu.com/admin/data.php?op=getapply",
    save_buyout:"https://tiyan.xianniuzu.com/admin/data.php?op=savebuyout",
    save_cancel_order:"https://tiyan.xianniuzu.com/admin/data.php?op=savecancel",
    turn_overdue:"https://tiyan.xianniuzu.com/admin/data.php?op=turnoverdue",
    set_receive:"https://tiyan.xianniuzu.com/admin/data.php?op=setreceive",
    set_complete:"https://tiyan.xianniuzu.com/admin/data.php?op=setcomplete",
    set_consign:"https://tiyan.xianniuzu.com/admin/data.php?op=setconsign",
    save_logistic:'https://tiyan.xianniuzu.com/admin/data.php?op=savelogistic',
    get_order_logistic:'https://tiyan.xianniuzu.com/admin/data.php?op=getlogistic',
    get_customer_addr:'https://tiyan.xianniuzu.com/admin/data.php?op=getcustomeraddr',
    save_addr:'https://tiyan.xianniuzu.com/admin/data.php?op=savecustomeraddr',
    confirm_order:'https://tiyan.xianniuzu.com/admin/data.php?op=confirmorder',
    save_addition:"https://tiyan.xianniuzu.com/admin/data.php?op=saveaddition",
    get_info:"https://tiyan.xianniuzu.com/admin/data.php?op=orderdetail",
    save_offline:"https://tiyan.xianniuzu.com/admin/data.php?op=saveoffline",
    get_logistic_detail:"https://tiyan.xianniuzu.com/admin/data.php?op=logisticdetail",
    get_orderdetail:"https://tiyan.xianniuzu.com/admin/data.php?op=orderdetail",
    get_order_bill:"https://tiyan.xianniuzu.com/admin/data.php?op=orderbill",
    get_billno:"https://tiyan.xianniuzu.com/admin/data.php?op=billno",
    save_pbi:"https://tiyan.xianniuzu.com/admin/data.php?op=savepbi",
    save_offincome:"https://tiyan.xianniuzu.com/admin/data.php?op=saveincome",
    get_trade:"https://tiyan.xianniuzu.com/admin/data.php?op=gettrade",
    get_decrease:"https://tiyan.xianniuzu.com/admin/data.php?op=getordermoney",
    get_deposit:"https://tiyan.xianniuzu.com/admin/data.php?op=getdeposit",
    set_buyoutprice:"https://tiyan.xianniuzu.com/admin/data.php?op=setbuyout",
    confirm_ReReceive:"https://tiyan.xianniuzu.com/admin/data.php?op=rereceive",
    get_estimate:"https://tiyan.xianniuzu.com/admin/data.php?op=getestimate",
    get_estimate_order:"https://tiyan.xianniuzu.com/admin/data.php?op=getestimateorder",
    save_estimate:"https://tiyan.xianniuzu.com/admin/data.php?op=saveestimate",
    add_remark:"https://tiyan.xianniuzu.com/admin/data.php?op=addremark",
    get_seller_remark:"https://tiyan.xianniuzu.com/admin/data.php?op=getremark",
    getcompesation:"https://tiyan.xianniuzu.com/admin/data.php?op=getcompesation",
    save_mitigate:"https://tiyan.xianniuzu.com/admin/data.php?op=savemitigate",
    getadditioninfo:"https://tiyan.xianniuzu.com/admin/data.php?op=getadditioninfo",
    restoreorder:"https://tiyan.xianniuzu.com/admin/data.php?op=restoreorder",
    checkreturn:"https://tiyan.xianniuzu.com/admin/data.php?op=checkreturn",
    riskinfo:"https://tiyan.xianniuzu.com/admin/data.php?op=riskinfo",
    savereject:"https://tiyan.xianniuzu.com/admin/data.php?op=savereject",

    //系统，角色管理
    get_role_list:"https://tiyan.xianniuzu.com/admin/data.php?op=getrolelist",
    get_role:"https://tiyan.xianniuzu.com/admin/data.php?op=getrole",
    set_role:"https://tiyan.xianniuzu.com/admin/index.php?entry=role&op=saverole",
    operator_list:"https://tiyan.xianniuzu.com/admin/data.php?op=getoplist",
    save_operator:"https://tiyan.xianniuzu.com/admin/data.php?op=saveop",
    get_permission:"https://tiyan.xianniuzu.com/admin/data.php?op=getpermission",
    save_role:"https://tiyan.xianniuzu.com/admin/data.php?op=saverole",
    get_operator:'https://tiyan.xianniuzu.com/admin/data.php?op=getopinfo',
    save_password:'https://tiyan.xianniuzu.com/admin/data.php?op=savepassword',
    delrole:"https://tiyan.xianniuzu.com/admin/data.php?op=delrole",
    update_status:"https://tiyan.xianniuzu.com/admin/data.php?op=changestatus",
    delop:"https://tiyan.xianniuzu.com/admin/data.php?op=delop",
    //登录
    login_url:"https://tiyan.xianniuzu.com/admin/data.php?op=login",


    //商户
    savemerchant:'https://tiyan.xianniuzu.com/admin/data.php?op=savemerchant',
    merchantlist:'https://tiyan.xianniuzu.com/admin/data.php?op=merchantlist',
    disablemerchant:'https://tiyan.xianniuzu.com/admin/data.php?op=disablemerchant',
    enablemerchant:'https://tiyan.xianniuzu.com/admin/data.php?op=enablemerchant',
    getmerchant:'https://tiyan.xianniuzu.com/admin/data.php?op=getmerchant',
    allmerchant:"https://tiyan.xianniuzu.com/admin/data.php?op=allmerchant",


    //店铺
    storelist:'https://tiyan.xianniuzu.com/admin/data.php?op=storelist',
    savestore:"https://tiyan.xianniuzu.com/admin/data.php?op=savestore",
    getstore:'https://tiyan.xianniuzu.com/admin/data.php?op=getstore',
    getstoreinfo:'https://tiyan.xianniuzu.com/admin/data.php?op=getstoreinfo',
    saverule:'https://tiyan.xianniuzu.com/admin/data.php?op=saverule',
    rulelist:'https://tiyan.xianniuzu.com/admin/data.php?op=rulelist',
    getrule:'https://tiyan.xianniuzu.com/admin/data.php?op=getrule',
    enablerule:'https://tiyan.xianniuzu.com/admin/data.php?op=enablerule',
    disablerule:'https://tiyan.xianniuzu.com/admin/data.php?op=disablerule',
    savedevice:'https://tiyan.xianniuzu.com/admin/data.php?op=savedevice',
    devicelist:'https://tiyan.xianniuzu.com/admin/data.php?op=devicelist',
    getdeviceinfo:'https://tiyan.xianniuzu.com/admin/data.php?op=getdeviceinfo',
    getmerchantlist:'https://tiyan.xianniuzu.com/admin/data.php?op=getmerchantlist',
    activedevice:"https://tiyan.xianniuzu.com/service/index.php?entry=binddv",
    unbinddevice:"https://tiyan.xianniuzu.com/admin/data.php?op=unbinddevice",
    disabledevice:"https://tiyan.xianniuzu.com/admin/data.php?op=disabledevice",
    enabledevice:"https://tiyan.xianniuzu.com/admin/data.php?op=enabledevice",
    savecav:"https://tiyan.xianniuzu.com/admin/data.php?op=savecav",
    cavlist:"https://tiyan.xianniuzu.com/admin/data.php?op=cavlist",
    cavbatchsave:"https://tiyan.xianniuzu.com/admin/data.php?op=cavbatchsave",
    getcavlog:"https://tiyan.xianniuzu.com/admin/data.php?op=getcavlog",
    delcav:"https://tiyan.xianniuzu.com/admin/data.php?op=delcav",
    togglecav:"https://tiyan.xianniuzu.com/admin/data.php?op=togglecav",

    orderlist:"https://tiyan.xianniuzu.com/admin/data.php?op=orderlist",
    orderlist2:"https://tiyan.xianniuzu.com/admin/testhuidiao.php",
    customerlist:"https://tiyan.xianniuzu.com/admin/data.php?op=customerlist",
    allstore:"https://tiyan.xianniuzu.com/admin/data.php?op=allstore",
    billlist:"https://tiyan.xianniuzu.com/admin/data.php?op=billlist",
    getearlist:"https://tiyan.xianniuzu.com/admin/data.php?op=getearlist",
    saveear:"https://tiyan.xianniuzu.com/admin/data.php?op=saveear",
    getear:"https://tiyan.xianniuzu.com/admin/data.php?op=getear",
    getsettle:"https://tiyan.xianniuzu.com/admin/data.php?op=getsettle",
    cashoutlog:"https://tiyan.xianniuzu.com/admin/data.php?op=cashoutlog",
    getcashout:"https://tiyan.xianniuzu.com/admin/data.php?op=getcashout",
    checkcashout:"https://tiyan.xianniuzu.com/admin/data.php?op=checkcashout",
    updatecashout:"https://tiyan.xianniuzu.com/admin/data.php?op=updatecashout",
    delorder:"https://tiyan.xianniuzu.com/admin/data.php?op=delorder",
    completecashout:"https://tiyan.xianniuzu.com/admin/data.php?op=completecashout",
    cashoutlist:"https://tiyan.xianniuzu.com/admin/data.php?op=cashoutlist",
    addcashout:"https://tiyan.xianniuzu.com/admin/data.php?op=addcashout",
    savecashout:"https://tiyan.xianniuzu.com/admin/data.php?op=savecashout",
    devicelog:"https://tiyan.xianniuzu.com/admin/data.php?op=devicelog",
    rulelog:"https://tiyan.xianniuzu.com/admin/data.php?op=rulelog",
    redirect:"https://tiyan.xianniuzu.com/redirect/mtcallback.php",
    endorder:"https://tiyan.xianniuzu.com/admin/data.php?op=endorder",
    changedevice:"https://tiyan.xianniuzu.com/admin/data.php?op=changedevice",
    getshoplist:"https://tiyan.xianniuzu.com/admin/data.php?op=getshoplist",
    getmerchantinfo:"https://tiyan.xianniuzu.com/admin/data.php?op=getmerchantinfo",
    savemerchantinfo:"https://tiyan.xianniuzu.com/admin/data.php?op=savemerchantinfo",
    Android:"https://tiyan.xianniuzu.com/admin/data.php?op=Android",
    Android2:"https://tiyan.xianniuzu.com/admin/data.php?op=Android2",

    //修改余额
    editbalance:"https://tiyan.xianniuzu.com/admin/data.php?op=editbalance",
    getbalancelog:"https://tiyan.xianniuzu.com/admin/data.php?op=getbalancelog",
    //上传文件
    uploadmobile:"https://tiyan.xianniuzu.com/admin/data.php?op=uploadmobile",
    exportsettle:"https://tiyan.xianniuzu.com/admin/data.php?op=exportsettle",
    exportcashout:"https://tiyan.xianniuzu.com/admin/data.php?op=exportcashout",
    exportbill:"https://tiyan.xianniuzu.com/admin/data.php?op=exportbill",

}