<template>
  <el-container class="container">
    <el-aside :style="defaultHeight" :width="asideWidth">
      <!-- <el-affix >
                <div :class="[isCollapse ? 'aside-logo-collapse' : 'aside-logo']">
                
                <el-image :class="[isCollapse ? 'logo-image-collapse' : 'logo-image']" :src="[isCollapse ? logosmall : logo]" fit="contain"/>

                </div>
      </el-affix> -->
      <!-- <el-menu
        class="aside-menu"
        router
        :default-active="$route.path"
        :collapse="isCollapse"
        background-color="#001529"
        text-color="#fff"
        active-text-color="#fff"
      >
        <div v-for="menu in routers" :key="menu.name">
          <div v-if="ismerchant == 1">
            <div v-if="menu.path != '/merchant' && menu.path != '/info' && menu.path != '/setting' && menu.path != '/activity'">
              <el-menu-item
                v-if="menu.children && menu.children.length === 1"
                :index="menu.children[0].path"
              >
                <i :class="menu.children[0].icon"></i>
                <template #title>
                  {{ menu.children[0].name }}
                </template>
              </el-menu-item>
               <el-submenu v-else-if="menu.children" :index="menu.path">
                <template #title>
                  <i :class="menu.icon"></i>
                  <span :class="[isCollapse ? 'is-collapse' : '']">{{
                    menu.name
                  }}</span>
                </template>
                <el-menu-item
                  v-for="child in menu.children"
                  :key="child"
                  :index="child.path"
                  v-show="child.path != '/role' && child.path != '/refund' && child.meta.show"
                >
                  <div>
                  <i :class="child.icon"></i>
                    {{ child.name }}
                  </div>
                </el-menu-item>
              </el-submenu>
            </div> 
          </div>
          <div v-else>
            <el-menu-item
                v-if="menu.children && menu.children.length === 1"
                :index="menu.children[0].path"
              >
                <i :class="menu.children[0].icon"></i>
                <template #title>
                  {{ menu.children[0].name }}
                </template>
              </el-menu-item>
              <el-submenu v-else-if="menu.children" :index="menu.path">
                <template #title>
                  <i :class="menu.icon"></i>
                  <span :class="[isCollapse ? 'is-collapse' : '']">{{
                    menu.name
                  }}</span>
                </template>
                <el-menu-item
                  v-for="child in menu.children"
                  :key="child"
                  :index="child.path"
                  v-show="child.meta.show == true && ismerchant!=1"
                >
                  <div>
                  <i :class="child.icon"></i>
                    {{ child.name }}
                  </div>
                </el-menu-item>
              </el-submenu> 
          </div>
        </div>
      </el-menu>  -->
      <el-menu
        router
        :default-active="$route.path"
        :collapse="isCollapse"
        background-color="#001529"
        text-color="#fff"
        active-text-color="#fff"
  >
  <div v-for="menu in routers" :key="menu.name">
    <div v-if="ismerchant == 1 && menu.path != '/merchant' && menu.path != '/info' && menu.path != '/setting' && menu.path != '/activity'">
      <el-menu-item v-if="menu.children && menu.children.length === 1"
                :index="menu.children[0].path" >
      <el-icon><icon-menu /></el-icon>
      <template #title>{{ menu.children[0].name }}</template>
    </el-menu-item>
    <el-sub-menu v-if="menu.children && menu.children.length > 1"
                :index="menu.path" >
      <template #title>
        <el-icon><location /></el-icon>
        <span>{{ menu.name }}</span>
      </template>
      <el-menu-item-group v-if="menu.children"  style="padding-left: 40px;margin-left: -20px;">
        <el-menu-item v-for="child in menu.children"
                  :key="child"
                  :index="child.path" v-show="child.path != '/role' && child.path != '/refund' && child.meta.show" >{{ child.name }}</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    </div>
    <div v-else>
      <el-menu-item v-if="menu.children && menu.children.length === 1"
                :index="menu.children[0].path" >
      <el-icon><icon-menu /></el-icon>
      <template #title>{{ menu.children[0].name }}</template>
    </el-menu-item>
    <el-sub-menu v-if="menu.children && menu.children.length > 1"
                :index="menu.path" >
      <template #title>
        <el-icon><location /></el-icon>
        <span>{{ menu.name }}</span>
      </template>
      <el-menu-item-group v-if="menu.children"  style="padding-left: 40px;margin-left: -20px;">
        <el-menu-item v-for="child in menu.children"
                  :key="child"
                  :index="child.path" v-show="child.meta.show == true && ismerchant!=1" >{{ child.name }}</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    </div>
    
  </div>
  </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <el-row :gutter="10">
          <el-col :span="1">
             <!--<div class="header-collapse" @click="onCollapse" style="cursor:pointer">
              <i
                :class="[isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold']"
              /> 
            </div>-->
          </el-col>
          <el-col :span="10" class="hidden-sm-and-down">
            <div class="header-breadcrumb">
              <el-breadcrumb
                separator="/"
                v-if="this.$route.matched[0].path != '/main'"
              >
                <el-breadcrumb-item :to="{ path: '/' }"
                  >工作台</el-breadcrumb-item
                >
                <el-breadcrumb-item
                  v-for="(matched, m) in this.$route.matched"
                  :key="m"
                >
                  {{ matched.name }}
                </el-breadcrumb-item>
              </el-breadcrumb>
              <el-breadcrumb separator="/" v-else>
                <el-breadcrumb-item>工作台</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </el-col>
          <el-col class="header-menu" :xs="{span:10,offset:9}" :md="{span:3,offset:9}" :lg="{span:2,offset:10}" >
            <HeadMenu></HeadMenu>
          </el-col>
        </el-row>
      </el-header>
      <el-main :style="defaultHeight">
        <router-view />
      </el-main>
    </el-container>
    <el-backtop target=".el-main"></el-backtop>
  </el-container>
</template>
<script>
import { onBeforeMount, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import HeadMenu from "@/components/HeadMenu";
import Cookies from 'js-cookie'
export default {
  components: {
    HeadMenu,
  },
  data(){
    return{
      ismerchant:'',
      addto: true,
    }
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      logo: require('@/assets/image/logo4.png'),
      logosmall: require('@/assets/image/logo5.png'),
      isCollapse: false,
      isOpen: false,
      asideWidth: "200px",
      defaultHeight: {
        height: "",
      },
      routers: [],
    });

    onBeforeMount(() => {
      console.log(router.options.routes)
      state.routers = router.options.routes;
      state.defaultHeight.height = document.body.clientHeight + "px";
    });

    const onCollapse = () => {
      if (state.isCollapse) {
        state.asideWidth = "200px";
        state.isCollapse = false;
        // state.isOpen = false;
      } else {
        // state.isOpen = true;
        state.isCollapse = true;
        state.asideWidth = "64px";
      }
    };

    const onRefresh = () => {
      router.push({ path: "main" });
    };
    /*
    const setCollapse = computed(()=>{
        return ducoment.body.clientWidth < 1000? isCollapse = true:isCollapse = false;
    });*/
    return {
      ...toRefs(state),
      onCollapse,
      onRefresh,
      
    };
  },
  created:function(){
    this.ismerchant = Cookies.get("IsMerchant");
    console.log("ismerchant--");
    console.log(Cookies.get("IsMerchant"));
  }
};
</script>
<style>
    .container .el-aside {
      background-color:#ffffff
    }
    .container .el-aside .el-menu {
        text-align: left;
        border-right: 0px;
    }
    .container .el-aside .aside-logo .logo-name{
        font-size: 15px;
        
    }
    /*
    .container .el-aside .aside-logo .logo-image{
        
        top: 6px;
        left: 4px;
    }
    
    .container .el-aside .aside-logo .logo-image-collapse{
        width: 30px;
        height: 30px;
    }*/
    .container .el-aside .aside-logo .logoinfo{
      margin-left: 20px;
      display:flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      
    }
    .container .el-aside .is-collapse {
        display: none;
    }
    .container .el-aside .aside-menu:not(.el-menu--collapse){
        width: 200px;
    }
    .el-submenu .el-submenu__title .el-submenu__icon-arrow.el-icon-arrow-right{
      display: none;
    }
    .container .el-aside .aside-logo{
        height: 80px;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container .el-aside .aside-logo-collapse{
        height: 60px;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container .el-aside::-webkit-scrollbar{
        width: 0px;
    }
    .container .el-aside{
        height: 100%;
        transition: all .5s;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .container .el-header{
            background: white;
            line-height: 60px;
            font-size: 24px;
            border-bottom: 1px #cccccc solid;
    }
    .container .el-header.header-collapse{
        cursor: pointer;
    }
    .container .el-header .header-breadcrumb{
        padding-top: 0.9em;
    }
    .container .el-header .header-menu{
        text-align: right;
        width: 200px;
    }
        
    .container .el-main{
            padding: 0.6em;
            overflow-x: hidden;
            overflow-y: auto;
    }
    .container .el-main::-webkit-scrollbar{
        width: 6px !important;
        height: 1px;
    }

    .container .el-main::-webkit-scrollbar-thumb{
        border-radius: 3px;
        background: rgba(0,0,0,0.5);
    }
    .container{
        background: rgb(245, 247, 249);
    }
</style>