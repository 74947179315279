import { createRouter, createWebHashHistory } from 'vue-router'
import constant from "@/constant"
import Layout from "@/layout/Layout"
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {title: constant.title, requireAuth: false},
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/main',
    component: Layout,
    icon: "el-icon-s-home",
    meta: {title: constant.title, requireAuth: true},
    redirect: '/home',
    children: [
        {
            path: "/home",
            name: "工作台",
            icon: "el-icon-s-home",
            meta: {title: constant.title, requireAuth: true},
            component: () => import('@/views/Home.vue')
        }
    ]
  },
  {
    path: '/permission',
    component: Layout,
    name:'权限管理',
    icon: "el-icon-user",
    meta: {title: constant.title, requireAuth: true},
    redirect: '/role',
    children: [
        {
            path: "/role",
            name: "账号角色管理",
            
            meta: {title: constant.title, requireAuth: true, show: true},
            component: () => import('@/views/Role.vue')
        },
        {
          path: "/operator",
          name: "人员列表",
          
          meta: {title: constant.title, requireAuth: true, show: true},
          component: () => import('@/views/Operator.vue')
        },
    ]
  },
  {
    path:'/merchant',
    name:'商户管理',
    component: Layout,
    icon: "el-icon-s-custom",
    meta: {title: "商户管理", requireAuth: true},
    children:[
      {
        path: "/merchantlist",
        name: "商户列表",
        
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/Merchant.vue')
      },
      {
        path: "/addmerchant",
        name: "新增商户",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/AddMerchant.vue')
      },
    ]
  },
  {
    path:'/device',
    name:'设置管理',
    component: Layout,
    icon: "el-icon-cpu",
    meta: {title: "设置管理", requireAuth: true},
    children:[
      {
        path: "/devicelist",
        name: "设备列表",
        icon: "el-icon-cpu",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/Device.vue')
      },
      
    ]
  },
  {
    path:'/customer',
    name:'客户管理',
    component: Layout,
    icon: "el-icon-user-solid",
    meta: {title: "客户管理", requireAuth: true},
    children:[
      {
        path: "/customer",
        name: "客户列表",
        icon: "el-icon-user-solid",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/Customer.vue')
      }
    ]
  },
  {
    path:'/deposit',
    name:'充值规则',
    component: Layout,
    icon: "el-icon-s-finance",
    meta: {title: "充值规则", requireAuth: true},
    children:[
      {
        path: "/rulelist",
        name: "充值规则",
        icon: "el-icon-s-finance",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/RuleList.vue')
      },
      
    ]
  },
  {
    path:'/marketing',
    name:'营销管理',
    component: Layout,
    icon: "el-icon-s-marketing",
    meta: {title: "营销管理", requireAuth: true},
    children:[
      {
        path: "/cav",
        name: "核销管理",
        icon: "el-icon-s-marketing",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/CAVList.vue')
      },
      
    ]
  },
  {
    path:'/store',
    name:'店铺管理',
    component: Layout,
    icon: "el-icon-s-shop",
    meta: {title: "店铺管理", requireAuth: true},
    children:[
      {
        path: "/storelist",
        name: "店铺列表",
        icon: "el-icon-s-shop",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/Store.vue')
      },
    ]
  },
  {
    path:'/order',
    name: "订单管理",
    component: Layout,
    icon: "el-icon-document-copy",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/orderlist",
          name: "订单管理",
          icon: "el-icon-document-copy",
          meta: {title: constant.title, requireAuth: true, show: true},
          component: () => import('@/views/Order.vue')
      },
      
      
    ]
  },
  {
    path:'/finance',
    name: "财务管理",
    component: Layout,
    icon: "el-icon-coin",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/bill",
          name: "账户流水",
          meta: {title: constant.title, requireAuth: true, show: true},
          component: () => import('@/views/Bill.vue')
      },
      {
        path: "/settle",
        name: "结算管理",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/Settle.vue')
      },
      {
        path: "/cashout",
        name: "提现管理",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/Cashout.vue')
      },
      {
        path: "/ear",
        name: "收支管理",
        meta: {title: constant.title, requireAuth: true, show: true},
        component: () => import('@/views/EAR.vue')
      },
    ]
  },
 
  
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach((to,from,next)=>{
  console.log("to--");
  console.log(to);
  console.log(from);
  // console.log(next);
  if(to.meta.title){
      document.title = to.meta.title
  }else{
      document.title = constant.title
  }
  let token = Cookies.get("token");
  console.log("token--" + token);
  let arr = Cookies.get('patharr');
  console.log("arr--" + arr);
  if(to.name != "Login"){
    if(!token){
      //没有登录过
      next({path:'/'})
    }else{
      if(arr){
        arr = JSON.parse(arr);
        console.log("解码");
        console.log(arr);
        if(arr.indexOf(to.path) == -1){
          alert("该账号无权限访问");
          next({path:'/main'})
        }
      }
      next();
    }
  }else{
    next();
  }
  
  //next();
    
})

export default router

